import React from 'react';
import Header from '../components/Header/index'
import SEO from '../components/SEO/seo';
import PersonalInformation from '../components/BookingForm/Form-Steps/personalInfo';

const PersonalInfo = () => (
  <>
    <Header />
    <SEO title="Need Taxi" />
      <PersonalInformation />
  </>
);

export default PersonalInfo;
